import { useEffect, useState } from "react";
import { getTokenFromCookie } from "./Routes/AppRoutes";
export default function useFetch(url) {
  // debugger;
  const [data, setData] = useState(null);

  const getToken = getTokenFromCookie();
  useEffect(() => {
    if (url !== "EmptyUrl") {
      apiFn(url);
    }
  }, []);

  const apiFn = (url) => {
    if (url === "") return;
    if (url === undefined) return;
    if (url === null) return;

    setData(null);
    fetch(`https://www.talabulilm.com/test_api2022/${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${getToken}`,
      },
    })
      .then((response) => response?.json())
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setData([]);
        console.log(error);
      });
  };

  return [data, apiFn];
}
