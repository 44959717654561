import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import md5 from "js-md5";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
function Login() {
  let initalValue = {
    its_id: "",
    password: "",
  };
  const navigate = useNavigate();
  const getToken = localStorage.getItem("admin-token");
  const [loginInitialForm, setLoginInitialForm] = useState(initalValue);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const handleChange = (e) => {
    setLoginInitialForm({
      ...loginInitialForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (value) => {
    setLoginIsLoading(true);
    // setCallMyTask(!callMytask);
    if (
      !(
        loginInitialForm.its_id &&
        loginInitialForm.password &&
        loginInitialForm.password === "Bchet@515253!"
      )
    ) {
      return false;
    }
    const pass = md5(`${loginInitialForm.its_id}tlbilm@12345678+515253`);

    const token = Buffer.from(
      `${loginInitialForm.its_id}:${pass}`,
      "utf8"
    ).toString("base64");

    fetch(`https://www.talabulilm.com/test_api2022/araiz/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        // document.cookie = `user_its=${json.its_id}, cookie.ver=${pass}`;
        // localStorage.setItem("admin-token", token);
        document.cookie = `user_its=${json.its_id}`;
        document.cookie = `ver=${pass}`;
        document.cookie = `role=${json.role}`;
        document.cookie = `tanzeem_id=${json.tanzeem_id}`;
        navigate("/");
        setLoginIsLoading(false);
      });
  };
  return (
    <>
      <form>
        <Row
          style={{
            backgroundColor: "#E5E5E5",
          }}
        >
          <Form.Group className="p-2  col-12" controlId="formBasicEmail">
            <input
              as="text"
              placeholder="Enter ITS ID"
              name="its_id"
              value={loginInitialForm.its_id}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group className="p-2  col-12" controlId="formBasicEmail">
            <input
              as="password"
              placeholder="Enter Password"
              name="password"
              value={loginInitialForm.password}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="p-2  col-1"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Row>
      </form>
    </>
  );
}

export default Login;
